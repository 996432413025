import React, {useState, ReactNode} from 'react';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import {DSL_List} from './List';
import {DSL_SortableOverlay} from './SortableOverlay';

export interface BaseItem {
  id: UniqueIdentifier;
}

export interface DSL_SortableListProps<T extends BaseItem> {
  items: T[];
  onChange(items: T[]): void;
  renderItem(item: T, isActive: boolean, hasActiveItem?: boolean): ReactNode;
}

export function DSL_SortableList<T extends BaseItem>({
  items,
  onChange,
  renderItem,
}: DSL_SortableListProps<T>) {
  const [activeItem, setActiveItem] = useState<T | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = ({active, over}: DragEndEvent) => {
    if (over && active.id !== over?.id) {
      const activeIndex = items.findIndex(({id}) => id === active.id);
      const overIndex = items.findIndex(({id}) => id === over.id);

      onChange(arrayMove(items, activeIndex, overIndex));
    }
    setActiveItem(null);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({active}) => {
        const nextActiveItem = items.find(item => item.id === active.id);
        setActiveItem(nextActiveItem ?? null);
      }}
      onDragEnd={handleDragEnd}
      onDragCancel={() => {
        setActiveItem(null);
      }}
    >
      <SortableContext items={items}>
        <DSL_List listStyleType="none">
          {items.map(item => (
            <React.Fragment key={item.id}>
              {renderItem(item, activeItem === item, !!activeItem)}
            </React.Fragment>
          ))}
        </DSL_List>
      </SortableContext>
      <DSL_SortableOverlay>
        {activeItem && renderItem(activeItem, true)}
      </DSL_SortableOverlay>
    </DndContext>
  );
}
