import {useEffect, useRef, useState} from 'react';

import {useBoolean, useWindowSize} from 'usehooks-ts';

import {DSL_BaseDrawerProps} from './BaseDrawer';
import {DSL_DrawerDetails, useDrawerContext} from './DrawerProvider';
import {
  DSL_DrawerAnchor,
  DSL_DrawerPositionProps,
  DSL_DrawerSize,
  DSL_DrawerVariant,
} from './types';
import {useDrawerSidebar} from './useDrawerSidebar';

export type DSL_UseDrawerArgs = {
  open?: DSL_BaseDrawerProps['open'];
  size?: DSL_DrawerSize;
  variant?: DSL_DrawerVariant;
  anchor?: DSL_DrawerAnchor;
  sidebarAnchor?: DSL_DrawerAnchor;
};

export function useDrawer({
  open = false,
  anchor = 'left',
  size = 'SM',
  variant = 'modal',
  sidebarAnchor = 'left',
}: DSL_UseDrawerArgs) {
  const refDrawer = useRef<HTMLDivElement>(null);
  const {width: viewWidth, height: viewHeight} = useWindowSize();
  const [id, setId] = useState<string>();
  const {value: isTransitioning, setValue: setIsTransitioning} = useBoolean();

  const {createDrawer, updateDrawer, removeDrawer, offsetTop, drawers} =
    useDrawerContext();

  const widthSizeMap = {
    XS: 400,
    SM: 512,
    MD: 640,
    LG: 960,
    FULL: viewWidth,
  } as const;

  const initialWidth = anchor === 'bottom' ? viewWidth : widthSizeMap[size];

  const {
    refSidebar,
    transition: sidebarTransition,
    width: sidebarWidth,
    height: sidebarHeight,
    ...sidebarPosition
  } = useDrawerSidebar({
    open,
    anchor,
    offsetTop,
    sidebarAnchor,
    initialDrawerWidth: initialWidth,
  });

  const position = calculateDrawerPosition({
    anchor,
    sidebarAnchor,
    offsetTop,
    sidebarHeight,
    sidebarWidth,
    initialDrawerWidth: initialWidth,
    initialDrawerHeight: viewHeight,
  });

  const {width, height} = position;

  const zIndexModifier =
    drawers
      .filter(drawer => drawer.open)
      .findIndex(drawer => drawer.id === id) + 1;

  useEffect(() => {
    const drawer: Omit<DSL_DrawerDetails, 'id'> = {
      open,
      anchor,
      variant,
      width,
      height,
      sidebarAnchor,
      sidebarWidth,
      sidebarHeight,
      isTransitioning,
    };

    if (!id) {
      setId(createDrawer(drawer));
    } else {
      updateDrawer({...drawer, id});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    open,
    variant,
    anchor,
    width,
    height,
    sidebarWidth,
    sidebarHeight,
    isTransitioning,
  ]);

  useEffect(() => () => removeDrawer(id), [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const el = refDrawer.current;
    if (!el) return;

    const onTransitionStart = (evt: TransitionEvent) => {
      if (evt.propertyName !== 'transform') return;
      setIsTransitioning(true);
    };

    const onTransitionEnd = (evt: TransitionEvent) => {
      if (evt.propertyName !== 'transform') return;
      setIsTransitioning(false);
    };

    el.addEventListener('transitionstart', onTransitionStart);
    el.addEventListener('transitionend', onTransitionEnd);

    return () => {
      el.removeEventListener('transitionstart', onTransitionStart);
      el.removeEventListener('transitionend', onTransitionEnd);
    };
  }, [setIsTransitioning]);

  return {
    ...position,
    sidebarPosition,
    refDrawer,
    refSidebar,
    sidebarTransition,
    open,
    anchor,
    zIndexModifier,
  };
}

function calculateDrawerPosition(args: {
  anchor: DSL_DrawerAnchor;
  sidebarAnchor: DSL_DrawerAnchor;
  offsetTop: number;
  initialDrawerWidth: number;
  initialDrawerHeight: number;
  sidebarHeight: number;
  sidebarWidth: number;
}): DSL_DrawerPositionProps & {width: number; height: number} {
  const dimensionMap = {
    // Drawer anchor
    left: {
      // Sidebar anchor
      bottom: {
        height: args.initialDrawerHeight - args.offsetTop - args.sidebarHeight,
        width: args.initialDrawerWidth,
        bottom: 0,
        left: 0,
      },
      // Sidebar anchor
      left: {
        height: args.initialDrawerHeight - args.offsetTop,
        width: args.initialDrawerWidth,
        maxWidth: `calc(100vw - ${args.sidebarWidth || 0}px)`,
        top: args.offsetTop,
        left: args.sidebarWidth,
      },
      // Sidebar anchor
      right: {
        height: args.initialDrawerHeight - args.offsetTop,
        width: args.initialDrawerWidth,
        maxWidth: `calc(100vw - ${args.sidebarWidth || 0}px)`,
        top: args.offsetTop,
        left: 0,
      },
    },
    // Drawer anchor
    right: {
      // Sidebar anchor
      bottom: {
        height: args.initialDrawerHeight - args.offsetTop - args.sidebarHeight,
        width: args.initialDrawerWidth,
        bottom: 0,
        right: 0,
      },
      // Sidebar anchor
      left: {
        height: args.initialDrawerHeight - args.offsetTop,
        width: args.initialDrawerWidth,
        maxWidth: `calc(100vw - ${args.sidebarWidth || 0}px)`,
        top: args.offsetTop,
        right: 0,
      },
      // Sidebar anchor
      right: {
        height: args.initialDrawerHeight - args.offsetTop,
        width: args.initialDrawerWidth,
        maxWidth: `calc(100vw - ${args.sidebarWidth || 0}px)`,
        top: args.offsetTop,
        right: args.sidebarWidth,
      },
    },
    // Drawer anchor
    bottom: {
      // Sidebar anchor
      bottom: {
        height: args.initialDrawerHeight - args.offsetTop - args.sidebarHeight,
        width: args.initialDrawerWidth,
        bottom: args.sidebarHeight,
        left: 0,
      },
      // Sidebar anchor
      left: {
        height: args.initialDrawerHeight - args.offsetTop,
        width: args.initialDrawerWidth - args.sidebarWidth,
        top: 0,
        left: args.sidebarWidth,
      },
      // Sidebar anchor
      right: {
        height: args.initialDrawerHeight - args.offsetTop,
        width: args.initialDrawerWidth - args.sidebarWidth,
        top: 0,
        right: args.sidebarWidth,
      },
    },
  };

  return {
    top: undefined,
    bottom: undefined,
    right: undefined,
    left: undefined,
    ...dimensionMap[args.anchor][args.sidebarAnchor],
  };
}
