import {NotificationType} from 'utils/machines/notificationsMachine';

import {
  DSP_AccessProductChangeSetNotificationCard,
  DSP_RoadManagementNotificationCard,
  DSP_WorkflowActivityNotificationCard,
  DSP_AccessPublicNoticeOfAmendmentNotificationCard,
  DSP_AccreditationApplicationNotificationCard,
} from './NotificationCard';
import {DSP_NotificationRendererComponent} from './types';

export const notificationTypeRendererMap: {
  [DSP_Type in NotificationType]: DSP_NotificationRendererComponent;
} = {
  [NotificationType.ACCESS_PRODUCT_CHANGE_SET]:
    DSP_AccessProductChangeSetNotificationCard,
  [NotificationType.ROAD_MANAGEMENT_ACCEPT]: DSP_RoadManagementNotificationCard,
  [NotificationType.ROAD_MANAGEMENT_AUTO_TRANSFER]:
    DSP_RoadManagementNotificationCard,
  [NotificationType.ROAD_MANAGEMENT_PROPOSAL]:
    DSP_RoadManagementNotificationCard,
  [NotificationType.ROAD_MANAGEMENT_REJECT]: DSP_RoadManagementNotificationCard,
  [NotificationType.ROAD_MANAGEMENT_CLAIM_CO_MANAGED]:
    DSP_RoadManagementNotificationCard,
  [NotificationType.ROAD_MANAGEMENT_RELINQUISH]:
    DSP_RoadManagementNotificationCard,
  [NotificationType.ACCESS_PRODUCT_APPLICATION_ACTIVITY_TASK]:
    DSP_WorkflowActivityNotificationCard,
  [NotificationType.ACCESS_PRODUCT_APPLICATION_ACTIVITY_INFORMATION]:
    DSP_WorkflowActivityNotificationCard,
  [NotificationType.PNA_PUBLISH]:
    DSP_AccessPublicNoticeOfAmendmentNotificationCard,
  [NotificationType.ACCREDITATION_APPLICATION]:
    DSP_AccreditationApplicationNotificationCard,
};
