/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {toInt, UserProfile} from '@regulatory-platform/common-utils';
import {UserModelControllerLoginDocument} from 'app/api/mutations/Login.generated';
import {UserModelControllerVerifyB2CTokenAccessDocument} from 'app/api/mutations/B2CLogin.generated';
import {UserModelControllerLogoutDocument} from 'app/api/mutations/Logout.generated';
import {UserModelControllerRefreshDocument} from 'app/api/mutations/Refresh.generated';
import {UserModelControllerVerifyTokenAccessDocument} from 'app/api/mutations/VerifyTokenAccess.generated';
import getQueryParams from 'app/router/utils/getQueryParams';
import Cookies from 'js-cookie';
import * as R from 'ramda';
import {from} from 'rxjs';
import getFetchDataResult from 'utils/apollo/getFetchDataResult';
import {LOCAL_TOKEN_EXPIRY, SESSION_TOKEN_EXPIRY} from 'utils/global';
import authMachine, {
  AuthStoreObservable,
  AuthStoreService,
} from 'utils/machines/authMachine';
import {
  clearAuthenticatedUserContext,
  setAuthenticatedUserContext,
} from 'utils/tracking';
import {assign, interpret} from 'xstate';
import {client} from '../apollo/clients';
import app from './appService';
import {ApolloError} from '@apollo/client';
import {getRefreshInputFromCookies} from '../apollo/links/authGetTokenLink';
import {UserModelControllerEncryptedReturnUrlDocument} from '../api/mutations/EncryptedReturnUrl.generated';
import {SSOLoginStorageKeys} from '../../utils/storage';

const initialQueryParams = getQueryParams(window.location.search);

type AuthRequestResult = {
  userProfile: UserProfile;
  refreshToken: string;
};

const NUMBER_RETRIES = 2;

const machine = authMachine.withConfig({
  guards: {
    hasRefreshToken: (): boolean => {
      return (
        !R.isNil(Cookies.get('refreshToken')) &&
        initialQueryParams.type !== 'loginWithToken'
      );
    },
    notHasRefreshToken: (): boolean => {
      return (
        R.isNil(Cookies.get('refreshToken')) ||
        initialQueryParams.type === 'loginWithToken'
      );
    },
    canRetry: (context): boolean => {
      return context.browserLoggingInRetries <= NUMBER_RETRIES;
    },
    cannotRetry: (context): boolean => {
      return context.browserLoggingInRetries > NUMBER_RETRIES;
    },
  },
  actions: {
    saveUserProfile: assign({
      userProfile: (context, event) => {
        const authResult = getFetchDataResult<AuthRequestResult>(event.data);
        if (!R.isNil(authResult) && !R.isNil(authResult.userProfile)) {
          const userProfile = authResult.userProfile;
          userProfile.id = toInt(userProfile.id as unknown as string) as number;
          return userProfile;
        }
        return context.userProfile as UserProfile;
      },
      props: context => {
        return R.assoc('apiError', undefined, context.props);
      },
      browserLoggingInRetries: 0,
    }),
    onError: assign({
      props: (context, event) => {
        return R.assoc('apiError', event.data as ApolloError, context.props);
      },
    }),
    resetContext: assign(context => {
      return R.mergeRight(context, {
        userProfile: undefined,
        props: {},
        browserLoggingInRetries: 0,
      });
    }),
    incrementRetryCounter: assign({
      browserLoggingInRetries: context => {
        return context.browserLoggingInRetries + 1;
      },
    }),
    setAppModeToIFrame: () => {
      app.service.send('IFRAME');
    },
    notifyLoginToTrackers: context => {
      //@TODO if previous state is verifyToken loginTokenMode === true
      if (!R.isNil(context.userProfile))
        setAuthenticatedUserContext(context.userProfile);
    },
    notifyLogoutToTrackers: () => {
      clearAuthenticatedUserContext();
    },
    redirectToReturnUrlIfAvailable: () => {
      // Extract the query string
      const queryString = window.location.search;
      // Combine the query string and fragment if needed
      const combinedParams = new URLSearchParams(queryString);
      let clientId = combinedParams.get('clientId');
      if (R.isNil(clientId)) {
        clientId = localStorage.getItem(
          SSOLoginStorageKeys.RETURN_URL_CLIENT_ID,
        );
      }
      if (!R.isNil(clientId) && !R.isEmpty(clientId)) {
        const refreshInput = getRefreshInputFromCookies();
        return client
          .mutate({
            mutation: UserModelControllerEncryptedReturnUrlDocument,
            variables: {
              encryptedReturnUrlInput: {
                ...refreshInput,
                clientId: parseInt(clientId),
              },
            },
            errorPolicy: 'none',
          })
          .then(result => {
            const returnUrl =
              result?.data?.userModelControllerEncryptedReturnUrl;
            const errorDetails = R.defaultTo(
              [],
              (result?.data as ApolloError)?.graphQLErrors?.[0]?.extensions
                ?.responseBody?.error?.details,
            ) as {message?: string; path?: string}[];
            localStorage.removeItem(SSOLoginStorageKeys.RETURN_URL_CLIENT_ID);
            if (!R.isNil(errorDetails)) {
              window.location.replace(
                `${returnUrl}/b2cLogin?errorDetails=${JSON.stringify(
                  errorDetails,
                )}`,
              );
            }
            if (returnUrl) {
              // Redirect to the returnUrl
              window.location.replace(returnUrl);
            }
            return;
          });
      }
    },
    logoutReturnUrl: () => {
      const fragmentString = window.location.hash.substring(1); // Remove the leading '#'
      const fragmentParams = new URLSearchParams(fragmentString);
      //Extract the returnUrl from the combined parameters
      const clientId = fragmentParams.get('clientId');
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      if (!R.isNil(clientId)) {
        window.location.replace(
          `${baseUrl}/login/?mode=beta&clientId=${clientId}`,
        );
      } else {
        window.location.replace(`${baseUrl}/login`);
      }
      return;
    },
    clearApolloStore: () => {
      client.clearStore();
    },
    clearSessionStore: () => {
      sessionStorage.clear();
    },
    setAuthCookies: (context, event) => {
      const authResult = getFetchDataResult<AuthRequestResult>(event.data);
      if (R.isNil(context.userProfile) || R.isNil(authResult)) return;

      const cookieOpts = {
        expires:
          Cookies.get('rememberMe') === 'true'
            ? LOCAL_TOKEN_EXPIRY
            : SESSION_TOKEN_EXPIRY,
      };
      Cookies.set(
        'refreshToken',
        authResult.refreshToken as string,
        cookieOpts,
      );
      Cookies.set('accountId', context.userProfile.accountId + '', cookieOpts);
      Cookies.set(
        'accountType',
        (context.userProfile.accountType ?? '') as string,
        cookieOpts,
      );
      Cookies.set('domain', context.userProfile.domain + '', cookieOpts);
    },
    removeAuthCookies: () => {
      Cookies.remove('rememberMe');
      Cookies.remove('refreshToken');
      Cookies.remove('accountId');
      Cookies.remove('accountType');
      Cookies.remove('domain');
    },
  },
  services: {
    apiRefresh: () => {
      return client.mutate({
        mutation: UserModelControllerRefreshDocument,
        errorPolicy: 'none',
      });
    },
    apiVerifyTokenAccess: (_context, event) => {
      Cookies.set('rememberMe', 'true', {expires: LOCAL_TOKEN_EXPIRY});
      return client.mutate({
        mutation: UserModelControllerVerifyTokenAccessDocument,
        variables: {
          verifyTokenAccessInput: event.record,
        },
        errorPolicy: 'none',
      });
    },
    apiChangeAccount: (_context, event) => {
      const cookieOpts = {
        expires:
          Cookies.get('rememberMe') === 'true'
            ? LOCAL_TOKEN_EXPIRY
            : SESSION_TOKEN_EXPIRY,
      };
      const selectedValue: unknown = event.value;
      if (selectedValue && R.is(String, selectedValue)) {
        const splitValue = R.split('-', selectedValue as string);
        if (splitValue?.length === 2) {
          Cookies.set('accountId', parseInt(splitValue[0]) + '', cookieOpts);
          Cookies.set('accountType', splitValue[1] as string, cookieOpts);
        }
      }
      return client.mutate({
        mutation: UserModelControllerRefreshDocument,
        errorPolicy: 'none',
      });
    },
    apiLogin: (_context, event) => {
      const record = event.record as {
        email: string;
        password: string;
        rememberMe: boolean;
        domain: string;
      };
      Cookies.set('rememberMe', record.rememberMe ? 'true' : 'false', {
        expires: record.rememberMe ? LOCAL_TOKEN_EXPIRY : SESSION_TOKEN_EXPIRY,
      });
      return client.mutate({
        mutation: UserModelControllerLoginDocument,
        variables: {
          loginInput: {
            email: record.email,
            password: record.password,
            domain: record.domain,
            recaptcha: event.value,
          },
        },
        errorPolicy: 'none',
      });
    },
    apiB2CLogin: (_context, event) => {
      Cookies.set('rememberMe', 'true', {expires: LOCAL_TOKEN_EXPIRY});
      return client.mutate({
        mutation: UserModelControllerVerifyB2CTokenAccessDocument,
        variables: {
          verifyB2CTokenAccessInput: {
            accessToken: event.value,
          },
        },
        errorPolicy: 'none',
      });
    },
    apiLogout: () => {
      return client.mutate({
        mutation: UserModelControllerLogoutDocument,
        errorPolicy: 'none',
      });
    },
  },
});

const service = interpret(machine) as AuthStoreService;

const state$ = from(service as never) as AuthStoreObservable;

export default {state$, service};
