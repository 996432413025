import React from 'react';

import {DSL_BaseDrawer, DSL_BaseDrawerProps} from '../BaseDrawer';

import {
  useNonModalDrawer,
  DSL_UseNonModalDrawerArgs,
} from './useNonModalDrawer';

export interface DSL_NonModalDrawerProps
  extends Pick<
      DSL_BaseDrawerProps,
      | 'onClose'
      | 'sidebar'
      | 'children'
      | 'id'
      | 'isHidden'
      | 'transitionDuration'
    >,
    DSL_UseNonModalDrawerArgs {
  name?: string;
}

export const DSL_NonModalDrawer = ({
  sidebar,
  children,
  onClose,
  id,
  isHidden,
  transitionDuration,
  name,
  ...args
}: DSL_NonModalDrawerProps) => {
  const viewProps = useNonModalDrawer(args);

  return (
    <DSL_BaseDrawer
      data-name={`non-modal-drawer${name ? `-${name}` : ''}`}
      sidebar={sidebar}
      onClose={onClose}
      id={id}
      isHidden={isHidden}
      transitionDuration={transitionDuration}
      {...viewProps}
    >
      {children}
    </DSL_BaseDrawer>
  );
};

DSL_NonModalDrawer.displayName = 'DSL_NonModalDrawer';

export {
  //
  /** @deprecated use DSL_NonModalDrawerProps instead*/
  DSL_NonModalDrawerProps as NonModalDrawerProps, //
  /** @deprecated use DSL_NonModalDrawer instead*/
  DSL_NonModalDrawer as NonModalDrawer,
};
